import React from 'react';
import { number } from 'prop-types';

import Button from '../common/Button/Button';
import errorIllustration from '../../assets/home/error-page-image.svg';

import { getMessages } from '../../locales';

const ErrorPage = ({ errorType }) => {
  const messages = getMessages();
  
  return <section className="container error-page">
    <div className="row align-items-center">
      <div className="error-page-content col-sm order-2 order-sm-1">
        <p className="error-page-number">{errorType}</p>
        <h1>{messages['error.title']}</h1>
        <p>{messages['error.desc']}</p>
        <Button
          linkTo="/"
          buttonStyle="secondary"
          size="medium"
          title={messages['error.page.button']}
        />
      </div>
      <div className="col-sm error-page-image order-1 order-sm-2">
        <img
          width="420"
          height="438"
          src={errorIllustration}
          alt={messages['error.page.altimage']}
        />
      </div>
    </div>
  </section>;
};

ErrorPage.propTypes = {
  errorType: number.isRequired,
};

export default ErrorPage;
